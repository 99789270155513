export function createPageNavigationButton() {
  // Массив страниц: "название страницы" - "ссылка"
  const pages = [
    { name: '+ Головна', link: 'index.html' },
    { name: '+ Категорія', link: 'category.html' },
    { name: '+ Картка товару', link: 'product.html' },
    { name: '+ Кошик', link: 'cart.html' },
    { name: '+ Кошик (пустий)', link: 'cart-empty.html' },
    {
      name: '+ Оформлення замовлення',
      link: 'checkout.html',
    },
    { name: '+ Доставка та оплата', link: 'delivery-and-payment.html' },
    { name: '+ Избранные', link: 'favorites.html' },
    { name: '+ Політика конфіденційності', link: 'privacy-policy.html' },
    { name: '+ Повернення та обмін', link: 'turn-and-exchange.html' },
    { name: '+ Де придбати', link: 'locations.html' },
    { name: '+ Блог', link: 'blog.html' },
    { name: '+ Контакти', link: 'contacts.html' },
    { name: '+ Стаття блогу', link: 'article.html' },
    { name: '+ Про нас', link: 'about-us.html' },
    { name: '+ Beauty-rewards', link: 'beauty-rewards.html' },
    { name: '+ Rewards-bazaar', link: 'rewards-bazaar.html' },
    { name: '+ Login', link: 'login.html' },
    { name: '+ Register', link: 'register.html' },
    { name: '+ Forgot password', link: 'forgot-password.html' },
    { name: '+ Recover password', link: 'recover-password.html' },
    { name: '+ Recommendations', link: 'recommendations.html' },
    { name: '+ Order history', link: 'order-history.html' },
    { name: '+ Addresses', link: 'addresses.html' },
    { name: '+ Sitemap', link: 'sitemap.html' },
  ];

  const container = document.createElement('div');
  container.innerHTML = `
        <!-- Кнопка -->
        <button id="pageNavButton" type="button" class="btn btn-primary position-fixed bottom-0 start-0 m-3 p-3 rounded-circle" style="z-index: 2;" data-bs-toggle="modal" data-bs-target="#pageNavModal">
            <i class="fas fa-bars"></i>
        </button>

        <!-- Модальное окно -->
        <div class="modal fade" id="pageNavModal" tabindex="-1" aria-labelledby="pageNavModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="pageNavModalLabel">Список сторінок</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group" id="pageNavList"></ul>
                    </div>
                </div>
            </div>
        </div>
    `;

  document.body.appendChild(container);

  // Получаем список и заполняем его ссылками из массива pages
  const pageNavList = document.getElementById('pageNavList');
  pages.forEach((page) => {
    const listItem = document.createElement('li');
    listItem.className = 'list-group-item';
    listItem.innerHTML = `<a href="${page.link}" class="text-decoration-none">${page.name}</a>`;
    pageNavList.appendChild(listItem);
  });
}
