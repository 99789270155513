// Import all plugins
import '../../scss/styles.scss';

import { createPageNavigationButton } from './pageNav.js'
createPageNavigationButton()

import {
  Tooltip as Tooltip,
  Toast as Toast,
  Popover as Popover,
} from 'bootstrap';

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'swiper/swiper-element-bundle.min.js';
import ResizeSensor from 'resize-sensor';
import StickySidebar from 'sticky-sidebar';

// Header logic
(function ()
{
  const getComputedCssVariable = (variableName) => {
    // Get raw CSS variable value
    let rawValue = getComputedStyle(document.body).getPropertyValue(variableName).trim();
  
    // Create a temporary element to resolve computed value
    let tempElement = document.createElement('div');
    tempElement.style.position = 'absolute';
    tempElement.style.visibility = 'hidden';
    tempElement.style.setProperty('height', rawValue);
    document.body.appendChild(tempElement);
  
    // Get the computed value
    let computedValue = tempElement.offsetHeight;
  
    // Clean up
    document.body.removeChild(tempElement);
  
    return computedValue;
  };

  let topBarHeight = getComputedCssVariable('--top-bar-height');
  const headerNode = document.querySelector('body');

  const setHeaderPosition = () =>
  {
    const documentScrollTop = document.documentElement.scrollTop;

    headerNode.classList.toggle('scrolled', documentScrollTop >= topBarHeight);
  };

  window.addEventListener('resize', () =>
  {
    topBarHeight = getComputedCssVariable('--top-bar-height');

    setHeaderPosition();
  });
  window.addEventListener('scroll', setHeaderPosition);
  setHeaderPosition();
})();


// Header dropdowns additional logic
(function ()
{
  const dropdown = document.querySelector('[aria-labelledby="catalogDropdown"]');

  if (dropdown) {
    const observer = new MutationObserver((mutationsList, observer) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const hasShowClass = dropdown.classList.contains('show');
          
          document.body.classList.toggle('navigation-opened', hasShowClass);
        }
      });
    });

    const config = {
      attributes: true,  // Listen for changes to attributes
      attributeFilter: ['class']  // Only observe 'class' attribute
    };

    observer.observe(dropdown, config);
  } else {
    console.log("Element with ID 'catalogDropdown' not found.");
  }

  // Make it possible to control navigation opening by pressing
  // on .navigation-toggle element 

  const navbarToggler = document.querySelectorAll('.navbar-toggle-action');

  navbarToggler.forEach(function (el)
  {
    el.addEventListener('click', function ()
    {
      dropdown.classList.toggle('show');
    });
  });
})();

// Slider-scrollbar functionalities
(function ()
{
  const nodes = document.querySelectorAll('.slider-scrollbar');

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      let width;

      if (entry.contentBoxSize) {
        width = entry.contentBoxSize[0].inlineSize;
      }
      else
      {
        width = entry.contentRect.width;
      }
      
      entry.target.style.setProperty('--scrollbar-container-width', `${ width }px`);
      entry.target.classList.toggle('slider-scrollbar-invisible', width >= entry.target.scrollWidth);
    }
  });

  nodes.forEach(function (node)
  {
    resizeObserver.observe(node);
  });
})();

// Quantity changer functionalities
// (function () {
//   const productQuantityNodes = document.querySelectorAll('.product-quantity');

//   productQuantityNodes.forEach(function (el)
//   {
//     const input = el.querySelector('input');
//     const plus = el.querySelector('.product-quantity-plus');
//     const minus = el.querySelector('.product-quantity-minus');

//     const setNewQuantity = function (q)
//     {
//       const normalizedQuantity = Math.max(1, parseInt(q));

//       minus.classList.toggle('product-quantity-disabled', normalizedQuantity <= 1);
//       input.value = normalizedQuantity;
//     };

//     input.addEventListener('keyup', function (e)
//     {
//       setNewQuantity(e.target.value);
//     });

//     el.addEventListener('click', function (e)
//     {
//       const currentQuantity = parseInt(input.value);

//       const clickedPlus = e.target.closest('.product-quantity-plus');
//       const clickMinus = e.target.closest('.product-quantity-minus');

//       if (clickedPlus)
//       {
//         setNewQuantity(currentQuantity + 1);
//         return;
//       }

//       if (clickMinus)
//       {
//         setNewQuantity(currentQuantity - 1);
//         return;
//       }
//     });
//   });
// })();